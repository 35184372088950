/* index.css */

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Background color for the whole app */
body {
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

/* Main container to center content */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Global headings style */
h1, h2, h3, h4, h5, h6 {
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Buttons */
button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

button:focus {
  outline: none;
}

/* Form inputs */
input[type="text"], input[type="email"], input[type="password"], textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  color: #333;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, textarea:focus {
  border-color: #3498db;
  outline: none;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #2c3e50;
  color: white;
}

td {
  background-color: #fff;
}

tr:hover td {
  background-color: #ecf0f1;
}

/* Responsive design */
@media (max-width: 768px) {
  body {
    padding: 10px;
  }

  .container {
    width: 100%;
    padding: 10px;
  }

  button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }

  table {
    font-size: 14px;
  }

  th, td {
    padding: 10px;
  }

  input[type="text"], input[type="email"], input[type="password"], textarea {
    padding: 12px;
  }
}

/* Navbar and header */
header {
  background-color: #34495e;
  color: white;
  padding: 15px 0;
  text-align: center;
}

header h1 {
  font-size: 2rem;
}

header nav {
  margin-top: 10px;
}

header nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

header nav a:hover {
  text-decoration: underline;
}

/* Custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #3498db;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ecf0f1;
}

/* Dark Grey Background Section */
.dark-bg {
  background-color: #2c3e50;
  color: white;
  padding: 30px 20px;
  border-radius: 8px;
}

/* Cards for each item */
.card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card h3 {
  margin-bottom: 10px;
}

.card p {
  color: #555;
}

/* Link styles */
a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Footer style */
footer {
  background-color: #34495e;
  color: white;
  text-align: center;
  padding: 15px 0;
  margin-top: 30px;
}
